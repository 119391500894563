<!-- 平台运行日志 -->
<template>
  <div id="platform-run">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>平台运行日志</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="requestParam">
            <!-- <el-form-item label="涉及功能">
              <el-input placeholder="请输入涉及功能" clearable v-model.trim="requestParam.operationName"></el-input>
            </el-form-item> -->
            <el-form-item label="涉及模块">
              <el-input placeholder="请输入涉及模块" clearable v-model.trim="requestParam.operationType"></el-input>
            </el-form-item>
            <el-form-item label="对接信息">
              <el-input placeholder="请输入对接信息" clearable v-model.trim="requestParam.transmitMessage"></el-input>
            </el-form-item>
            <el-form-item label="描述">
              <el-input placeholder="请输入描述" clearable v-model.trim="requestParam.describe"></el-input>
            </el-form-item>
            <el-form-item label="开始时间">
              <el-date-picker placeholder="请选择开始时间" type="date" value-format="yyyy-MM-dd" clearable
                v-model="requestParam.createdAtStart" :append-to-body="false" @change="checkDate"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间">
              <el-date-picker placeholder="请选择结束时间" type="date" value-format="yyyy-MM-dd"
                v-model="requestParam.createdAtEnd" :picker-options="pickerOptions" :append-to-body="false"
                @change="checkDate"></el-date-picker>
            </el-form-item>
          </el-form>
          <div class="query-button">
            <el-button type="primary" icon="el-icon-search" @click="formQuery">查询</el-button>
          </div>
        </div>

        <el-table class="table" :data="tableData" v-loading="loading" element-loading-text="加载中"
          element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.3)">
          <div slot="empty">
            <div v-if="tableData.length == 0">
              <div class="empty-data-view">
                <div class="table-text">
                  {{ loading ? "" : "暂无数据" }}
                </div>
              </div>
            </div>
          </div>
          <el-table-column align="center" type="index" label="序号" width="75">
            <template slot-scope="scope">
              <span>{{
                (requestParam.pageNum - 1) * requestParam.pageSize +
                scope.$index +
                1
              }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column align="center" prop="operationNameDict" label="涉及功能">
          </el-table-column> -->
          <el-table-column align="center" prop="operationType" label="涉及模块的类型">
          </el-table-column>
          <el-table-column align="center" prop="transmitMessage" label="对接信息">
          </el-table-column>
          <el-table-column align="center" prop="describe" label="描述" width="200">
          </el-table-column>
          <el-table-column align="center" prop="responseMessage" label="返回信息" width="200">
            <template slot-scope="scope">
              <template v-if="isOverflow(scope.row.responseMessage)">
                <el-tooltip :content="scope.row.responseMessage" placement="top" :popper-class="'tooltip'">
                  <div class="ellipsis">{{ scope.row.responseMessage }}</div>
                </el-tooltip>
              </template>
              <template v-else>
                <div class="ellipsis">{{ scope.row.responseMessage }}</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="创建时间" width="175">
          </el-table-column>
          <el-table-column align="center" label="操作" prop="operate">
            <template slot-scope="scope">
              <div class="operate">
                <el-button type="text" @click="viewDialog(scope.row)">详情</el-button>
                <!-- <span>|</span>
                <el-button type="text" @click="deleteDialog(scope.row)">删除</el-button> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page" @click="homePage">首页</button>
          <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
            @size-change="changeSize" @current-change="changeCurrent">
          </el-pagination>
          <button class="tail-page" @click="tailPage">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input size="mini" class="pagination-input" v-model.number="inputValue" @input="checkPageNum"
              @change="inputValueChange"></el-input>
            <span class="text">页</span>
            <button class="confirm" @click="confirm">确定</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="详情" :visible.sync="viewVisible" width="751px">
      <el-form class="dialog-form" ref="form" :inline="true" :model="detailsData">
        <!-- <el-form-item label="涉及功能">
          <el-input v-model="detailsData.operationNameDict" :readonly="true"></el-input>
        </el-form-item> -->
        <el-form-item label="涉及模块类型">
          <el-input v-model="detailsData.operationType" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="对接信息">
          <el-input v-model="detailsData.transmitMessage" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="detailsData.describe" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="返回信息">
          <el-input type="textarea" autosize v-model="detailsData.responseMessage" :readonly="true"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">

      </div>
    </el-dialog>
    <el-dialog title="删除" :visible.sync="deleteVisible" width="436px">
      <div class="dialog-text">确定要删除该数据吗？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deletePlatform">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { eventApi } from "@/api/event.js";
export default {
  name: "",

  data() {
    return {
      tableData: [],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        createdAtEnd: null,
        createdAtStart: null,
        operationName: "",
        operationType: "",
        transmitMessage: "",
        describe: "",
      },
      total: 0,
      exportDialog: false,
      inputValue: "",
      viewVisible: false,
      deleteVisible: false,
      detailsData: {},
      id: "",
      loading: false
    };
  },
  created() {
    this.$store.commit('increment', 'Eios控制面板')
    this.$store.commit('selectChild', '平台运行日志')
    this.$store.commit('selectChildren', '')
  },
  mounted() {
    this.queryPlatform();
  },
  methods: {
    isOverflow(content) {
      const lineHeight = 16;
      const numLines = Math.ceil(content.length / 42); // 每行大概显示42个字符
      // console.log("====>",numLines);
      return numLines >= 3;
    },
    viewDialog(item) {
      Object.assign(this.detailsData, item);
      this.viewVisible = true;
    },
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    deleteDialog(item) {
      this.id = item.id;
      this.deleteVisible = true;
    },
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryPlatform();
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryPlatform();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryPlatform();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryPlatform();
      this.inputValue = "";
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryPlatform();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryPlatform();
    },
    checkDate() {
      if (this.requestParam.createdAtStart && this.requestParam.createdAtEnd) {
        const startTime = new Date(this.requestParam.createdAtStart).getTime();
        const endTime = new Date(this.requestParam.createdAtEnd).getTime();
        if (startTime > endTime) {

          this.requestParam.createdAtEnd = null;
        }
      }

    },
    queryPlatform() {
      this.loading = true;
      eventApi.queryPlatform(this.requestParam).then((res) => {
        this.loading = false;
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
    deletePlatform() {
      eventApi.deletePlatform(this.id).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.deleteVisible = false;
          this.queryPlatform();
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
  },
  computed: {
    pickerOptions() {
      const _this = this;
      return {
        disabledDate(time) {
          const limitDate = new Date(_this.requestParam.createdAtStart);
          limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

          return time.getTime() < limitDate.getTime();
        },
      };
    },
  },
  watch: {
    // 如果只需要监听对象中的一个属性值，则可以做以下优化：使用字符串的形式监听对象属性：
    "requestParam.pageNum": {
      // 执行方法
      handler(newValue, oldValue) {
        this.inputValue = newValue;
      },
      deep: true, // 深度监听
      immediate: true, // 第一次改变就执行
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#platform-run {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}

.main {
  width: 100%;
  padding: 20px 26px 28px 25px;
  box-sizing: border-box;
}

/* 弹窗 */
.dialog-form {
  width: 688px;
  margin: 41px 32px 0 31px;
}

.dialog-form :deep().el-form-item__label {
  width: 100px;
  color: #c9d4f1;
  font-size: 12px;
  text-align: right;
}

.dialog-form :deep() .el-input .el-input__inner,
.dialog-form :deep() .el-textarea textarea {
  width: 539px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}
</style>
<style>
.tooltip {
  max-width: 600px;
  /* 设置最大宽度 */
  line-height: 20px;
  max-height: 600px;
  overflow: auto;
}

</style>
